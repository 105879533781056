export const INVESTIGATING = 'INVESTIGATING';
export const RESOLVED = 'RESOLVED';
export const MONITORING = 'MONITORING';
export const IDENTIFIED = 'IDENTIFIED';
export const DUPLICATED = 'DUPLICATED';

export const ROLES = {
    COMMANDER: 'Commander',
    COMMUNICATION_LEAD: 'Communication lead',
    CREATOR: 'Creator',
    PARTICIPANT: 'Participant'
};

export const TASK_TYPES = {
    RECURRING: 'RECURRING',
    BASIC: 'BASIC'
};

export const TASK_KEYS = {
    ROWNAME: {
        ID: 'rowName',
        LABEL: 'Tasks'
    },
    ASSIGNED_TO: { ID: 'assignedTo', LABEL: 'Assigned To' },
    PHASE: { ID: 'phase', LABEL: 'Phase' },
    TASK_ORDER: {
        ID: 'taskOrder',
        LABEL: 'Task Order'
    },
    ICON: { ID: 'icon', LABEL: '' },
    REQUIRED: { ID: 'required', LABEL: '' },
    LINK: { ID: 'link', LABEL: '' }
};

export const TASK_OWNER_TYPES = {
    ROLE: 'ROLE',
    USER: 'USER'
};

// Event types
export const INCIDENT_CREATED = 'INCIDENT_CREATED';
export const START_OF_CUSTOMER_IMPACT = 'START_OF_CUSTOMER_IMPACT';
export const START_OF_INCIDENT_IMPACT = 'START_OF_INCIDENT_IMPACT';
export const END_OF_CUSTOMER_IMPACT = 'END_OF_CUSTOMER_IMPACT';
export const END_OF_INCIDENT_IMPACT = 'END_OF_INCIDENT_IMPACT';
export const TIME_OF_DETECTION = 'TIME_OF_DETECTION';
export const INCIDENT_DETECTED = 'INCIDENT_DETECTED';
export const STATUS_FIELD_CHANGED = 'STATUS_FIELD_CHANGED';
export const PRIORITY_FIELD_CHANGED = 'PRIORITY_FIELD_CHANGED';
export const SEVERITY_FIELD_CHANGED = 'SEVERITY_FIELD_CHANGED';
export const TYPE_FIELD_CHANGED = 'TYPE_FIELD_CHANGED';
export const COMMANDER_FIELD_CHANGED = 'COMMANDER_FIELD_CHANGED';
export const COMMUNICATION_LEAD_FIELD_CHANGED = 'COMMUNICATION_LEAD_FIELD_CHANGED';
export const CHAT_HIGHLIGHT = 'CHAT_HIGHLIGHT';
export const POSTMORTEM_HIGHLIGHT = 'POSTMORTEM_HIGHLIGHT';
export const POSTMORTEM_STATE_CHANGED = 'POSTMORTEM_STATE_CHANGED';
export const FILE_UPLOAD = 'FILE_UPLOAD';
export const FILE_REMOTE_UPLOAD = 'FILE_REMOTE_UPLOAD';
export const IMAGE_LINK = 'IMAGE_LINK';
export const IMAGE_UPLOAD = 'IMAGE_UPLOAD';
export const PAGER_TRIGGERED = 'PAGER_TRIGGERED';
export const OPSGENIE_TRIGGERED = 'OPSGENIE_TRIGGERED';
export const LINK = 'LINK';
export const CUSTOM = 'CUSTOM';
export const ROLES_FIELD_CHANGED = 'ROLES_FIELD_CHANGED';
export const POSTMORTEM_ONLY_HIGHLIGHT = 'POSTMORTEM_ONLY_HIGHLIGHT';
export const SWIMLANE_CREATED = 'SWIMLANE_CREATED';
export const SWIMLANE_OWNER_CHANGNED = 'SWIMLANE_OWNER_CHANGNED';
export const SWIMLANE_DUE_DATETIME_UPDATED = 'SWIMLANE_DUE_DATETIME_UPDATED';
export const SWIMLANE_THEORY_UPDATED = 'SWIMLANE_THEORY_UPDATED';
export const SWIMLANE_STATUS_CHANGNED = 'SWIMLANE_STATUS_CHANGNED';
export const TIME_TO_IDENTIFICATION = 'TIME_TO_IDENTIFICATION';
export const TIME_TO_ACTION = 'TIME_TO_ACTION';
export const DURATION_OF_CUSTOMER_IMPACT = 'DURATION_OF_CUSTOMER_IMPACT';
export const DURATION_OF_INCIDENT_IMPACT = 'DURATION_OF_INCIDENT_IMPACT';
export const TIME_TO_RESOLUTION = 'TIME_TO_RESOLUTION';
export const FIRST_RESOLUTION_TIME = 'FIRST_RESOLUTION_TIME';
export const LAST_RESOLUTION_TIME = 'LAST_RESOLUTION_TIME';
export const LAST_RESOLUTION_TIME_FIELD_CHANGED = 'LAST_RESOLUTION_TIME_FIELD_CHANGED';
export const ALERT_TRIGGERED = 'ALERT_TRIGGERED';
export const PAGER_INCIDENT_ACKNOWLEDGED = 'PAGER_INCIDENT_ACKNOWLEDGED';
export const PAGER_INCIDENT_RESOLVED = 'PAGER_INCIDENT_RESOLVED';
export const PAGER_INCIDENT_UNACKNOWLEDGED = 'PAGER_INCIDENT_UNACKNOWLEDGED';
export const PAGER_INCIDENT_PRIORITY_CHANGED = 'PAGER_INCIDENT_PRIORITY_CHANGED';
export const PAGER_INCIDENT_ESCALATED = 'PAGER_INCIDENT_ESCALATED';
export const PAGER_INCIDENT_RESPONDER_ADDED = 'PAGER_INCIDENT_RESPONDER_ADDED';
export const PAGER_INCIDENT_RESPONDER_REPLIED = 'PAGER_INCIDENT_RESPONDER_REPLIED';

export const DISPLAY_EVENT_TYPES = {
    INCIDENT_CREATED: 'Created At',
    START_OF_CUSTOMER_IMPACT: 'Start of Customer Impact',
    END_OF_CUSTOMER_IMPACT: 'End of Customer Impact',
    START_OF_INCIDENT_IMPACT: 'Start of Incident impact',
    END_OF_INCIDENT_IMPACT: 'End of Incident Impact',
    LAST_RESOLUTION_TIME: 'Last Resolution Time'
};

export const TABS_IDS = {
    EVENTS_TAB: 'EVENTS_TAB',
    TASKS_TAB: 'TASKS_TAB',
    TICKETS_TAB: 'TICKETS_TAB',
    COMMS_TAB: 'COMMS_TAB',
    FOLLOW_UP_ACTIONS_TAB: 'FOLLOW_UP_TAB',
    RUNBOOKS_TAB: 'RUNBOOKS_TAB'
};
export const TAB_URL_DISPLAY = {
    EVENTS_TAB: 'events',
    TASKS_TAB: 'tasks',
    TICKETS_TAB: 'tickets',
    COMMS_TAB: 'comms',
    FOLLOW_UP_ACTIONS_TAB: 'follow-up-actions',
    RUNBOOKS_TAB: 'runbooks'
};

export const EXPORT_SECTION_KEYS = {
    INCIDENT_DESCRIPTION: 'incident_description',
    SUMMARY: 'summary',
    IMPACT: 'impact',
    TIMELINE: 'timeline',
    ACTIONS: 'actions',
    ROOT_CAUSE: 'root_cause',
    ANALYSIS: 'analysis',
    CUSTOM: 'custom',
    CUSTOM_TAB: 'custom_tab'
};

export const EXPORT_SECTIONS = [
    { key: EXPORT_SECTION_KEYS.SUMMARY, name: 'Retrospective Summary' },
    { key: EXPORT_SECTION_KEYS.INCIDENT_DESCRIPTION, name: 'Incident Description' },
    { key: EXPORT_SECTION_KEYS.IMPACT, name: 'Impact' },
    { key: EXPORT_SECTION_KEYS.TIMELINE, name: 'Timeline' },
    { key: EXPORT_SECTION_KEYS.ACTIONS, name: 'Follow Up Actions' },
    { key: EXPORT_SECTION_KEYS.ROOT_CAUSE, name: 'Root Cause' },
    { key: EXPORT_SECTION_KEYS.ANALYSIS, name: 'Analysis' },
    { key: EXPORT_SECTION_KEYS.CUSTOM, name: 'Custom Questions' },
    { key: EXPORT_SECTION_KEYS.CUSTOM_TAB, name: 'Custom Tab' }
];

export const SPEED_DIAL_MENU = {
    DELETE_INCIDENT: 'DELETE_INCIDENT',
    EDIT_POSTMORTEM: 'EDIT_POSTMORTEM',
    DOWNLOAD_MARKDOWN: 'DOWNLOAD_MARKDOWN',
    DOWNLOAD_PDF: 'DOWNLOAD_PDF',
    EXPORT_TO_EMAILS: 'EXPORT_TO_EMAILS'
};

export const INCIDENT = 'Incident';

export const INCIDENTS = 'Incidents';

export const ADVANCED_FILTERS: { [key: string]: string } = {
    SEARCH: 'search',
    SEVERITY: 'severity',
    STATUS: 'status',
    CREATOR: 'creator',
    INCLUDE_TAGS: 'include_tags',
    EXCLUDE_TAGS: 'exclude_tags',
    TYPE: 'type',
    CREATED: 'created',
    UPDATED: 'updated',
    IS_POSTMORTEM_REQUIRED: 'is_postmortem_required'
};

export const EVENT_SOURCE_TYPE = {
    WEB: 'WEB'
};
export const DRAWER_WIDTH = 300;

export const LOADING_SKELETONS_NUMBER = 8;

export const LOADING_MORE_SKELETONS_NUMBER = 2;

export const INCIDENT_CATEGORIES = [
    {
        categoryId: 'contributing-factor',
        categoryLabel: 'contributing-factor',
        predefinedTags: ['Tag 1', 'Tag 2']
    },
    {
        categoryId: 'customer',
        categoryLabel: 'Customer',
        predefinedTags: []
    },
    {
        categoryId: 'service',
        categoryLabel: 'Service',
        predefinedTags: []
    },
    {
        categoryId: 'product',
        categoryLabel: 'Product',
        predefinedTags: ['Resto', 'Locko']
    }
];

export const INCIDENT_TAGS = {
    'contributing-factor': [
        {
            _id: 456,
            label: 'Tag 1',
            category: 'contributing-factor'
        },
        {
            _id: 1244,
            label: 'Tag 2',
            category: 'contributing-factor'
        },
        {
            _id: 1245,
            label: 'Tag 3',
            category: 'contributing-factor'
        }
    ],
    customer: [],
    product: [],
    service: []
};

export const TZ_DATE_FORMAT = 'MMM D, YYYY h:mm A';

export const BRIDGE_TYPE = {
    HANGOUT: 'HANGOUT',
    ZOOM: 'ZOOM',
    GOTOMEETING: 'GOTOMEETING',
    MICROSOFT_TEAMS: 'MICROSOFT_TEAMS'
};

export const TICKET_TYPE = {
    JIRA: 'JIRA',
    TRELLO: 'TRELLO',
    SERVICE_NOW: 'SERVICE_NOW',
    AUTOTASK: 'AUTOTASK',
    GITHUB_ISSUES: 'GITHUB_ISSUES',
    CUSTOM: 'CUSTOM',
    BLAMELESS_LIGHT: 'BLAMELESS_LIGHT',
    BLAMELESS: 'BLAMELESS'
};
